import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

const HelpOutPage = () => (
    <div className="body">
        <Layout>
            <SEO title="Help Out" />
            <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
                <section className="section">
                    <div className="page-heading-parent">
                        <h1>We Need Your Support.</h1>
                        <div className="line grey"></div>
                    </div>
                    <h2>Ways to Help <em>The Pub</em></h2>
                    <p>To continue producing independent student media, The Pub relies on support from alumni, students, community members, academic departments, and local businesses.</p>
                    <div className="w-richtext">
                        <h4>Donate to <em>The Pub</em></h4>
                        <p>To make a donation click the link below and on the form, under designation options, choose “Give to area of your choice”, then select “Other Student Activity” in the menu that pops up. Finally specify “The Pub” in the gift comment.</p>
                    </div>
                    <div className="button-parent page">
                        <Button name="Make a Donation" to="https://securelb.imodules.com/s/1156/donate/donate.aspx?sid=1156&gid=1&pgid=1068&cid=2247" />
                    </div>
                </section>
            </div>
        </Layout>
    </div>
)
export default HelpOutPage
